<template>
  <div>
    <div v-swiper:mySwiper="swiperOptions">
      <div class="swiper-wrapper">
        <div class="swiper-slide" :key="index" v-for="item,index in dataList">
          <a :href="`${ item.url }`">{{ item.txt }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {
      swiperOptions: {
        width: 1820,
        loop: true,   // 循环播放
        breakpoints: { 
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2,
            spaceBetween: 10
          },
          768: {  //当屏幕宽度大于等于768 
            slidesPerView: 3,
            spaceBetween: 20
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 6,
            spaceBetween: 10
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  mounted() {
    this.moveAction();
  },
  methods: {
    // 自动播放
    moveAction() {
      setInterval(() => {
        this.mySwiper.slideNext(500, false);
      }, this.dataList.length * 350);
    }
  }
}
</script>

<style scoped>
#links > div > div > div > div > div > a {
  display: block;
  line-height: 50px;
  text-align: center;
  padding: 0 5px;
  background: white;
  box-shadow: 0px 1px 11px 0px rgba(56, 56, 56, 0.11);
  text-decoration: none;
  color: #000;
  outline: none;
}
</style>