<template>
  <div>
    <div v-swiper:mySwiper="swiperOptions">
      <div class="swiper-wrapper">
        <div class="swiper-slide" :key="index" v-for="item,index in dataList">
          <a :href="`${ item.url }`">
            <img :src="require('../../../assets/coo' + `${ item.pic }` + '.png')">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      swiperOptions: {
        loop: true,   // 循环播放
        breakpoints: { 
          320: {  //当屏幕宽度大于等于320
            slidesPerView: 2,
            spaceBetween: 10
          },
          768: {  //当屏幕宽度大于等于768 
            slidesPerView: 3,
            spaceBetween: 20
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 5,
            spaceBetween: 30
          }
        }
      }, 
    }
  },
  computed: {
    // swiper() {
    //     return this.$refs.mySwiper.$swiper
    //   }
  },
  mounted() {
    this.moveAction();
    // 
  },
  methods: {
    // 自动播放
    moveAction() {
      setInterval(() => {
        this.mySwiper.slideNext(500, false);
      }, this.dataList.length * 1000);
    }
  }
}
</script>

<style scoped>


</style>