<template>
<div style="">
	<!-- 头部 -->
		<div class="page-header">
			<!-- 导航 -->
			<TopBar id="nav"/>
			<!-- 走马灯 -->
			<div id="bg">
				<el-carousel 
					trigger="click" 
					:autoplay='playOf'
					indicator-position="outside"  
					:height="bannerHeight + 'px'"
				>
					<el-carousel-item v-for="item,index in bannerList" :key="index">
						<div id="carContext">
							<h4>{{ item.title }}</h4>
							<p>{{ item.txt }}</p>
						</div>
						<!-- :src="item.pic" -->
						<img src="../../assets/27432B5E002411C31184936D8D5_B48902DB_483E.png"
							style="width: 100%;
								position: absolute;
								left: 0;
								top: 0;
								height: 100%;"
						/>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
	<!-- 主体 -->
		<div class="page-container">
			<!-- 一楼板块 -->
			<div id="cards" class="containers">
				<div class="card" v-for="card,index in productList" :key="index">
					<img :src="require('../../assets/linkic' + `${ card.pic }` + '.png')">
					<div class="card-content">
						<h3>{{ card.title }}</h3>
						<p>{{ card.txt }}</p>
					</div>
					<el-button round @click="cardClick(card.url)">了解详情</el-button>
					<el-button type="primary" round @click="cardClick(card.url)">立即使用</el-button>	
				</div>
			</div>
			<!-- 二楼简介 -->
			<div id="abouts" class="containers">
				<div id="pic">
					<img src="../../assets/Profile.png">
				</div>
				<div id="txt">
					<router-link to="/Profile" title="公司简介">
						<div id="title">
							关于核创
							<div id="line1"></div>
							<div id="line2"></div>
						</div>
						<div id="info">西安核创源科技有限公司创立于二〇一八年一月，公司以服务国家重大科技需求为使命，以促进产学研结合和科技成果转化为宗旨，以核能及相关领域的应用软件自主化研发和技术服务为核心业务，为国内外核能及相关领域提供软件研发、技术攻关、技术服务与咨询等。公司坚持以需求为导向，面向复杂的实际工程问题提供解决方案，坚持以量身定制技术为核心，做到精心服务全方位掌控；坚持以自主攻关为使命，为攻克卡脖子难题做出自己的贡...</div>
						<span>查看更多 &gt;&gt; </span>
					</router-link>
				</div>
			</div>
			<!-- 三楼方案 -->
			<div id="solution" class="cleaner">
				<div id="content" class="containers cleaner">
					<div id="title" class="cleaner">
						<h4>解 决 方 案</h4>
					</div>
					<div id="solt-left">
						<div>
							<div class="cleaner">
								<p>核电厂换料校核计算</p>
								<img src="../../assets/icon11.png">
							</div>
							<div class="cleaner">
								<p>调试技术支持</p>
								<img src="../../assets/icon12.png">
							</div>
							<div class="cleaner">
								<p>运行技术改造</p>
								<img src="../../assets/icon13.png">
							</div>
						</div>
					</div>
					<div id="solt-middle">
						<div id="img">
							<img src="../../assets/service-bg.png">
						</div>
					</div>
					<div id="solt-right">
						<div>
							<div class="cleaner">
								<p>分析软件研发</p>
								<img src="../../assets/icon14.png">
							</div>
							<div class="cleaner">
								<p>数据处理</p>
								<img src="../../assets/icon15.png">
							</div>
							<div class="cleaner">
								<p>其它</p>
								<img src="../../assets/icon16.png">
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 热点滚动 -->
			<div id="hot-news" class="containers" >
				<NoticScroll :dataList="noticList"/>	
			</div>
			<!-- 新闻 -->
			<div id="news">
				<div id="content" class="containers cleaner">
					<div id="title" >
							<router-link :to="'/news'">新 闻 中 心</router-link>
					</div>
					<div id="news-items">
						<div class="news-content"
							v-for="item,index in newsPicList" :key="index"
							@click="toNews(`${item.url}`)"
						>
							<div class="news-pic">
								<img :src="require('../../assets/news_item' + `${ item.pic }` + '.jpg')">
							</div>
							<div class="news-date">
								<i>{{ item.date.split('-')[2] }}</i>
								<b>{{ item.date.split('-')[0] }}-{{ item.date.split('-')[1] }}</b>
							</div>
							<div class="news-txt">
								<h4>{{ item.title }}</h4>
								<span></span>
								<p>{{ item.txt }}</p>
							</div>
						</div>						
					</div>
					<div id="news-lists">
						<div class="news-item"
							v-for="item,index in newsItemsList" :key="index"
							@click="toNews(`${ item.url }`)"
						>
							<div class="news-date">
								<i>{{ item.date.split('-')[2] }}</i>
								<b>{{ item.date.split('-')[0] }}-{{ item.date.split('-')[1] }}</b>
							</div>
							<p>{{ item.title }}</p>
						</div>
					</div>
				</div>
			</div>	
			<!-- 合作	-->
			<div id="partner">
				<div id="content" class="containers cleaner">
					<div id="title" >
						<a href="#">合 作 单 位</a>
					</div>
					<Partners :dataList="partnersList"/>
				</div>
			</div>
			<!-- 友链 -->
			<div id="links">
				<div id="content" class="containers cleaner">
					<div id="title" >
						<a href="#">友 情 链 接</a>
					</div>
					<Links :dataList="linksList" />
				</div>
				<div id="links-button">
					<div class="swiper-button-next"></div>
					<div class="swiper-button-prev"></div>
				</div>
			</div>	
		</div>
	<!-- 底部 -->
		<div class="page-footer">
			<Footer/>
		</div>
</div>
</template>

<script>
// 引入导航组件
import TopBar from  './common/TopBar.vue'
// 引入热点组件
import NoticScroll from './common/NoticScroll.vue'
// 引入合作伙伴
import Partners from './common/Partners.vue'
// 引入友情链接
import Links from './common/Links.vue'
// 引入页脚
import Footer from './common/Footer.vue'

	export default {
		components: {
			'TopBar': TopBar,		// 注册导航组件
			'NoticScroll': NoticScroll,		// 注册热点组件
			'Partners': Partners,		// 注册合作伙伴	
			'Links': Links,		// 注册友情链接  
			'Footer': Footer
		},
    props: {
      
		},
		data () {
			return {
				bannerList: [],
				productList: [ ],
				noticList: [  // 滚动新闻列表
					// { title: "PHYTRA5征文通知", date: "2019-10-28" },
					// { title: "中西部核学会联合体2019年学术年会", date: "2019-06-28" },
					// { title: "BEPU2020征文通知", date: "2019-06-06" },
					// { title: "PHYSOR2020征文通知", date: "2019-06-05" },
					// { title: "ICAPP征文通知", date: "2019-06-03" }
				],
				newsPicList: [	// 新闻右侧卡片列表
					{ 
						pic: "01", 
						title: "西安交大NECP团队成功高精度预测“华龙一号”全球首堆的临界硼浓度", 
						txt: "2020年10月21日15时09分，我国自主研发的三代核电“华龙一号”全球首堆福清核电厂5号机组首次达到临界，向建成投产迈出了重要一步。科技创新服务国家需求，扎根西北践行“西迁精神”，西安交通大学NECP团队经过十余年的理论研究，依托中国西部科技创新港的科研平台，研发了最新的、具有完全自主知识产权的先进压水堆燃料管理软件Bamboo-C（两步法）和数值反应堆确定论高保真计算软件NECP-X（一步法）。福清核电厂业主公司和NECP团队分别采用Bamboo-C和NECP-X软件，背靠背地建立了“华龙一号”精细的堆芯计算模型，提前预测了“华龙一号”首次临界的堆芯关键安全物理量——临界硼浓度。", 
						date: "2021-02-23", 
						url: "/news/0" 
					},
					{ pic: "02", 
						title: "核创公司顺利通过方圆标志认证集团“三体系”监督审核", 
						txt: "2020年12月10日～11日，西安核创能源科技有限公司接受了方圆标志认证集团陕西有限公司（以下简称方圆）的“质量管理体系认证（QMS）”、“环境管理体系认证（EMS）”和“职业健康安全管理体系认证（OHSMS）”的监督审核。今年恰逢职业健康安全管理体系新版国家标准（GB/T 45001-2020）发布（2020年3月6日），公司予以高度重视，按公司惯例于体系外审前安排进行当年度公司内审。为提高工作绩效、更好地将贯标与日常工作结合，公司内审组将体系内审与自查工作结合起来，对公司各职能部门开展详尽有效的自查自检。", 
						date: "2021-02-23", 
						url: "/news/1" 
					}
				],
				newsItemsList: [	// 新闻左侧新闻列表
					{ title: "西安核创能源科技有限公司成功举办核电厂教育与信息化技术培训班", date: "2021-02-23", url: "/news/2" },
					{ title: "第十八届反应堆数值计算与粒子输运学术会议 暨2020年反应堆物理会议顺利召开", date: "2020-08-31", url: "/news/3" },
					{ title: "核创公司董事长和总经理等一行六人赴福建福清核电厂进行技术交流", date: "2020-08-14", url: "/news/4" },
					{ title: "核创公司董事长和总经理等一行六人赴海南昌江核电厂进行技术交流", date: "2020-08-13", url: "/news/5" },
					{ title: "加拿大沈炜教授访问NECP实验室并做学术报告", date: "2019-12-09", url: "/news/6" }
				],
				// 走马灯自动播放  默认不播放 false
        playOf: false,
				// 图片父容器高度
        bannerHeight: 1300,
        // 浏览器宽度
        screenWidth: 0
			}
		},
		beforeMount() {
			// 从数据仓库获取数据
				// banner
				this.bannerList = this.$store.state.bannerList;
				// 一楼卡片
				this.productList = this.$store.state.productList;
				// 合作单位
				this.partnersList = this.$store.state.partnersList;
				// 友情链接
				this.linksList= this.$store.state.linksList;
				// 通知公告
				let obj = this.$store.state.noticList;
				for (let i = 0; i < obj.length; i++) {
					this.noticList.push( {
						title: obj[i].title,
						date: obj[i].date
					})
				}
		},
		mounted() {
			// 走马灯等比缩放
				// 首次加载时,需要调用一次
				this.screenWidth = window.innerWidth;
				this.setSize();
				// 窗口大小发生改变时,调用一次
				window.onresize = () => {
					this.screenWidth = window.innerWidth;
					this.setSize();
      }
    },
		methods: {
			// 走马灯尺寸计算
				setSize: function () {
					// 通过浏览器宽度(图片宽度)计算高度
					this.bannerHeight = 600 / 1920 * this.screenWidth;
				},
			// 一楼板块连接
				cardClick(str) {
					// console.log(str);
					this.$router.push(str);
				},
			// 新闻点击跳转
				toNews(url) {
					this.$router.push(url);
				}
		}
	}
</script>

<style>
/* 高度坍塌 */
.cleaner::after {
  content: "";
	clear: both;
	content: '';
	display: block;
	width: 0;
	height: 0;
	visibility: hidden;
}
/* 头部 */
div.page-header {
	position: relative;
}
/* 导航 */
div#nav {
	position: absolute;
	top: 0;
	z-index: 1000;
	width: 100%;
	padding: 0;	
}
/* 子菜单 */
.el-menu-item * {
	vertical-align: initial;
	width: 105px;
}
.el-menu-item:hover {
  background-color: #fff0 !important;
  color: #ffd200 !important;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
	height: 106px !important;
	line-height: 106px !important;
	border-bottom: 1px solid transparent !important;
	color: #909399;
}
.el-popper {
  background-color: rgba(255, 255, 255, 0.6)  !important;
  border: 0 !important;
  border-radius: 0 !important;
	padding: 0;
}
.popper__arrow,
.el-popper_arrow, .el-popper__arrow::before {
  display: none !important;
}
.el-dropdown-menu {
	background-color: rgba(255, 255, 255, 0.7)  !important;
  border: 0 !important;
  border-radius: 0 !important;
  border-top: 3px solid #ffd200 !important;
  font-weight: normal;
}
.el-dropdown-menu__item {
	position:	relative;
	color: #323232;
}
.el-dropdown-menu__item:not(.is-disabled):hover, .el-dropdown-menu__item:focus {
	background-color: rgba(255, 255, 255, 1) !important;
  border: 0 !important;
  border-radius: 0 !important;
  color: #0066e4 !important;
}
.el-dropdown-link {
	color: #fff;
}
.el-dropdown-link:hover, .el-dropdown-link:active {
	color: #ffd200 !important;
}
/* 走马灯 */
div#carContext {
	position: relative;
	z-index: 1000;
	top: 13.75rem;
	max-width: 1280px;
	margin: 0 auto;
	width: 100%;
	text-align: left ;
	overflow: hidden;
}
div#carContext h4 {
	display: block;
	width: 70%;
	padding-bottom: 6px;
	font-size: 1.625rem;
	color: #ffffff;
	font-weight: bold;
	line-height: 2.5rem;
}
div#carContext p {
	padding-bottom: 0px;
	font-size: 15px;
	color: #FFFFFF;
	text-shadow: 0px -1px #000000;
	line-height: 1.5rem;
	opacity: 0.8;
}
.el-carousel__indicators{
	position: relative;
	top: -220px;
	left: -595px;
}
.el-carousel__indicator--horizontal {
	padding: 12px 0;
}
.el-carousel__button {
	height: 1px;
}
/* 走马灯左右箭头 */
.el-carousel__arrow {
	height: 90px;
	border-radius: 5px;
	background-color: #0080ff;
}
.el-carousel__arrow:hover {
	background-color: #1d1de2;
}
/* 主体 */
div.page-container {
	position: relative;
	width: 100%;
}
div.page-container > div.containers {
	position: relative;
	max-width: 1280px;
  margin: 0 auto;
}
/* 主体一楼板块 */
div#cards {
	position: relative;
	z-index: 1000;
	top: -100px;
}
div#cards > div.card {
	float: left;
	width: 20.5%;
	background: #fff;
	border-radius: 20px;
	margin-left: 2%;
	padding: 44px 1.5% 30px 1.5%;
	transition: 0.4s;
	box-shadow: 0 6px 20px #aaa;
	display: block;
}
div#cards > div.card:hover {
	padding: 54px 1.5% 25px 1.5%;
	transition: 0.6s;
	cursor: pointer;
}
div#cards > div.card:first-child {
	margin-left: 0;
}
div#cards> div.card > img {
	display: block; 
	margin: 0 auto 20px auto;
}
div#cards > div.card > div.card-content {
	height: 175px;
	overflow: hidden;
	padding-bottom: 50px;
}
div#cards > div.card > div.card-content > h3 {
	font-size: 24px;
	color: #343434;
	text-align: center;
	padding: 0 15px;
	line-height: 2.25rem;
	transition: 0.4s;
}
div#cards > div.card > div.card-content > h3:hover {
	color: #225ad7;
	transition: 0.4s;
}
div#cards > div.card > div.card-content > p {
	padding-top: 15px;
	font-size: 0.875rem;
	color: #323232;
	height: 100px;
	line-height: 1.625rem;
	text-align: justify;
	text-align-last: justify;
	text-justify: distribute;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
}
div#cards > div.card > .el-button {
	display: block;
	margin: 0 auto;
	width: 50%;
	text-align: center;
	font-size: 14px;
}
div#cards > div.card > button.el-button.el-button--primary.is-round {
	margin-top: 25px;
	background-color: #225ad7;
}
.el-button:hover, .el-button:focus {
	border: 1px solid #225ad7;
	background: #225ad7;
	margin-top: 1.5625rem;
	color: #fff;
}
/* 主体二楼简介 */
div#abouts {
	position: relative;
	top: -20px;
}
div#abouts > div#pic {
	width: 42.96%;
	overflow: hidden;
	float: left;
	height: auto;
	transition: 0.4s;
}
div#abouts > div#pic:hover {
	box-shadow: 0 2px 10px #aaa;
	transition: 0.4s;
}
div#abouts > div#pic > img {
	display: block;
	width: 100%;
}
div#abouts > div#txt {
	position: absolute;
	float: right;
	right: 0;
	top: 35.9375rem;
	width: 57%;
	background: white;
	padding: 1.875rem;
}
div#abouts > div#txt > a {
	display: block;
	text-decoration: none;
	color: #000;
	outline: none;
}
div#abouts > div#txt > a > div#title {
	display: block;
	position: relative;
	font-size: 26px;
	color: #323232;
	height: 40px;
	line-height: 40px;
	padding-bottom: 10px;
	float: left;
}
div#abouts > div#txt > a > div#title:hover {
	color: #0066E4;
}
div#abouts > div#txt > a > div#title > div#line1 {
	position: absolute;
	left: 121px;
	top: 20px;
	width: 160px;
	height: 1px;
	background: #eee;
}
div#abouts > div#txt > a > div#title > div#line2 {
	position: absolute;
	left: 121px;
	top: 20px;
	width: 15px;
	height: 1px;
	background: #0066e4;
}
div#abouts > div#txt > a > div#info {
	font-family: "Microsoft Yahei";
	color: #323232;
	font-size: 14px;
	line-height: 30px;
	text-align: justify;
	float: left;
}
div#abouts > div#txt > a > span {
	padding: 0.375rem 0.75rem;
	background: #0066E4;
	display: inline-block;
	color: white;
	font-size: 0.875rem;
	margin-top: 1rem;
	transition: 0.4s;
	float: left;
}
div#abouts > div#txt > a > span:hover {
	padding: 0.375rem 2.2rem;
	transition: 0.4s;
}
/* 主体三楼方案 */
div#solution {
	background: url('../../assets/fwbg.png') no-repeat center;
	padding: 4rem 0 10rem 0;
	margin-top: 5rem;
	position: absolute;
	width: 100%;
	top: 950px;
} 
div#solution > div#content {
	position: relative;
	max-width: 1280px;
  margin: 0 auto;
}
div#solution > div#content > div#title {
	margin-bottom: 4.875rem;
}
div#solution > div#content > div#title > h4 {
	font-size: 2.25rem;
	text-align: center;
	background: url('../../assets/servicebg.png') center no-repeat;
	color: white;
	padding: 12px 0;
}
div#solution > div#content > div#solt-left,
div#solution > div#content > div#solt-right {
	float: left;
	width: 35%;
	margin-top: -13px;
}
div#solution > div#content > div#solt-left > div,
div#solution > div#content > div#solt-right > div {
	width: 100%;
	position: relative;
}
div#solution > div#content > div#solt-left > div > div.cleaner {
	text-align: center;
	padding: 40px 0;
	position: relative;
	right: -40px;
}
div#solution > div#content > div#solt-left > div > div.cleaner:nth-child(2) {
	right: 0px;
}
div#solution > div#content > div#solt-left > div > div.cleaner > p {
	float: left;
	font-size: 1rem;
	color: white;
	line-height: 50px;
	width: calc(100% - 60px);
	text-align: right;
	margin-right: 10px;
}
div#solution > div#content > div#solt-left > div > div.cleaner > img,
div#solution > div#content > div#solt-right > div > div.cleaner > img {
	float: left;
	width: 50px;
}
div#solution > div#content > div#solt-middle {
	float: left;
	width: 30%;
}
div#solution > div#content > div#solt-middle > div#img {
	position: relative;
}
div#solution > div#content > div#solt-middle > div#img > img {
	width: 92%;
	position: relative;
	display: block;
	margin: 0 auto;
}

div#solution > div#content > div#solt-right > div > div.cleaner {
	text-align: center;
	padding: 40px 0;
	position: relative;
	left: -40px;
}
div#solution > div#content > div#solt-right > div > div.cleaner:nth-child(2) {
	left: 0px;
}
div#solution > div#content > div#solt-right > div > div.cleaner > p {
	float: right;
	font-size: 1rem;
	color: white;
	line-height: 50px;
	width: calc(100% - 60px);
	text-align: left;
	margin-left: 10px;
}
/* 热点滚动 */
div#hot-news {	
	position: absolute;
	z-index: 1000;
  top: 1780px;	
  width: 100%;
	margin: 0 auto;	
	padding: 2rem 0;
	max-width: 1920px;
	background-color: #fff;
}
div#notic {
	position: relative;
	max-width: 1280px;
	margin: 0 auto;
	width: 100%;
	height: 30px;
	overflow: hidden;
}
/* 新闻 */
div#news {
	margin-top: 5rem;
	margin-top: 5rem;
	position: absolute;
	width: 100%;
	top: 1870px;
	max-width: 1920px;
} 
div#news > div#content {
	position: relative;
	max-width: 1280px;
  margin: 0 auto;
}
div#news > div#content > div#title {
	margin-bottom: 4.875rem;
}
div#news> div#content > div#title > a {
	display: block;
	font-size: 2.25rem;
	text-align: center;
	background: url('../../assets/newsbg.png') center no-repeat;
	width: 100%;
	padding: 12px 0;
	color: #323232;
	font-weight: bold;
	text-decoration: none;
	outline: none;
}
div#news> div#content > div#title > a:hover {
	color: #0066e4;
}
/* 新闻左侧卡片 */
div#news div#news-items {
	width: 65%;
	margin-right: 3%;
	float: left;
}
div#news div#news-items > div.news-content {
	float: left;
	width: 47.5%;
	transition: 0.6s;
	position: relative;
	display: block;
	color: #000;
	cursor: pointer;
}
div#news div#news-items > div.news-content:nth-child(2) {
	margin-left: 5%;
}
div#news div#news-items > div.news-content > div.news-pic {
	transition: 0.6s;
}
div#news div#news-items > div.news-content > div.news-pic  > img {
	display: block;
	width: 100%;
	height: 280px;
	transition: 0.6s;
	border: 0;
}
div#news div#news-items > div.news-content > div.news-pic  > img:hover {
	transform: rotateY(10deg);
	transition: 0.6s;
}
div#news div#news-items > div.news-content > div.news-date {
	position: absolute;
	top: 220px;
	left: 20px;
	padding: 0.625rem;
	background: rgba(0,102,228,0.8);
}
div#news div#news-items > div.news-content > div.news-date > i {
	display: block;
	font-style: normal;
	font-family: "times new roman";
	font-size: 2.25rem;
	color: white;
	text-align: center;
}
div#news div#news-items > div.news-content > div.news-date > b {
	display: block;
	font-style: normal;
	font-family: arial;
	font-size: 0.75rem;
	text-align: center;
	font-weight: normal;
	color: white;
}
div#news div#news-items > div.news-content > div.news-txt {
	background: white;
	padding: 30px 20px 20px 20px;
}
div#news div#news-items > div.news-content > div.news-txt:hover > h4 {
	color: #0066E4;
	transition: 0.6s;
}
div#news div#news-items > div.news-content > div.news-txt > h4 {
	font-size: 1rem;
  line-height: 1.5rem;
	display: block;
	font-weight: bold;
	text-align: left;
}
div#news div#news-items > div.news-content > div.news-txt:hover > span {
	width: 100%;
	transition: 0.6s;
}
div#news div#news-items > div.news-content > div.news-txt > span {
	display: block;
	width: 30px;
	height: 1px;
	background: #0066e4;
	transition: 0.6s;
	margin: 10px 0 10px 0;
}
div#news div#news-items > div.news-content > div.news-txt > p {
	display: block;
	font-size: 0.75rem;
	color: #999999;
	line-height: 1.375rem;
	text-align: justify;
	text-align-last: justify;
	text-justify: distribute;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 7;
	overflow: hidden;
}
/* 新闻右侧列表 */
div#news div#news-lists {
	display: block;
	float: right;
	width: 32%;
}
div#news div#news-lists > div.news-item {
	display: block;
	color: #000;
	padding: 12px;
	background: white;
	margin-bottom: 0.625rem;
	cursor: pointer;
}
div#news div#news-lists > div.news-item:hover {
	background: #0066E4;
	transition: 0.6s;
}
div#news div#news-lists > div.news-item > div.news-date{
	width: 60px;
	float: left;
	border-right: 1px solid #DDDDDD;
	margin-right: 12px;
}
div#news div#news-lists > div.news-item > div.news-date > i {
	display: block;
	font-style: normal;
	font-family: "times new roman";
	font-size: 2.25rem;
	color: #999999;
	text-align: center;
}
div#news div#news-lists > div.news-item:hover > div.news-date > i,
div#news div#news-lists > div.news-item:hover > div.news-date > b,
div#news div#news-lists > div.news-item:hover > p {
	color: white;
	transition: 0.6s;
}
div#news div#news-lists > div.news-item > div.news-date > b {
	display: block;
	font-style: normal;
	font-family: arial;
	font-size: 0.75rem;
	text-align: center;
	color: #999999;
	font-weight: normal;
}
div#news div#news-lists > div.news-item > p {
	display: block;
	width: calc(100% - 74px);
	line-height: 1.5rem;
	padding-top: 6px;
	text-align: start;	
	text-justify: distribute;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;	
}
/* 合作单位 */
div#partner {
	position: absolute;
	display: block;
	top: 2650px;
	width: 100%;
	padding: 4rem 0;
	margin-top: 5rem;
	max-width: 1920px;
	background: url('../../assets/coobg.png') center no-repeat;
}
div#partner > div#content {
	max-width: 1280px;
	margin: 0 auto;
	width: 100%;
}
div#partner > div#content > div#title,
	div#links > div#content > div#title {
		margin-bottom: 2.875rem;
}
div#partner > div#content > div#title > a {
	font-size: 2.25rem;
	text-align: center;
	background: url('../../assets/coo.png') center no-repeat;
	color: white;
	padding: 12px 0;
	display: block;
	font-weight: bold;
	text-decoration: none;
	outline: none;
}
div#partner > div#content > div#title > a:hover {
	color: #f3e10d;
}
/* 友情链接 */
div#links {
	position: absolute;
	display: block;
	top: 3050px;
	width: 100%;
	padding: 4rem 0;
	margin-top: 5rem;
	max-width: 1920px;
}
div#links > div#content {
	display: block;
	max-width: 1280px;
	margin: 0 auto;
	width: 100%;
}
div#links > div#content > div#title > a {
	font-size: 2.25rem;
	text-align: center;
	background: url('../../assets/linkbg.png') center no-repeat;
	color: #323232;
	padding: 12px 0;
	display: block;
	font-weight: bold;
	text-decoration: none;
	outline: none;
}
div#links > div#content > div#title > a:hover {
	color: #0066e4;
}
div#links > div#links-button {
	max-width: 1310px;
	margin: 0 auto;
	position: relative;
	top: -30px;
	display: block;
	height: 28px;
}
div#links > div#links-button .swiper-button-prev{
	color: rgba(255, 255, 255, 0);
	width: 15px;
	height: 28px;
	background: url('../../assets/controls-l.png') no-repeat;
	cursor: pointer;
}
div#links > div#links-button .swiper-button-next{
	color: rgba(255, 255, 255, 0);
	width: 15px;
	height: 28px;
	background: url('../../assets/controls-r.png') no-repeat;
	cursor: pointer;
}
/* 页脚 */
div.page-footer {
	display: block;
	position: relative;
	top: 3430px;
	z-index: 100;
}
</style>