<template>
  <div id="notic">
    <el-carousel 
      height = "30px"
      :interval = "5000" 
      direction = "vertical"
      ref="scrollNotic"
    >
      <div id="button">
        <button style="left: 560px;" @click="handleClick('prev')">上一条</button>
        <button style="left: 580px;" @click="handleClick('next')">下一条</button>
      </div>
      <el-carousel-item v-for="item,index in dataList" :key="index">
        <router-link :to="`/notic/` + index">
          <h3>{{ item.title }}</h3>
          <p>{{ item.date }}</p>
        </router-link>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "HotNews",
  props: {
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleClick(val) {
      if(val === 'next') {
        this.$refs.scrollNotic.next()
      } else {
        this.$refs.scrollNotic.prev()
      }
    },
  }
}
</script>

<style scoped>
div#button {
  display:flex;
  flex-wrap: wrap;
  float: right;
  z-index: 1000;
  position: relative;
}
.el-carousel--vertical > div > div#button > button {
  margin: 0 10px;
  font-weight: bold;
  cursor: pointer;
  width: 5rem;
  height: 1.875rem;
  line-height: 1.875rem;
  border: 0;
  background-color: #f2f2f2;
}
.el-carousel--vertical > div > div#button > button:hover {
  background-color: #284ec2;
  color: #fff;
}
div.el-carousel__item{
  display:flex;
  flex-wrap: wrap;
}
div.el-carousel__item > a {
  display:flex;
  flex-wrap: wrap;
  line-height: 30px;
	text-decoration: none;
	color: #000;
	outline: none;  
}
div.el-carousel__item > a > h3 {
  float: left;
  font-size: 1rem;
  font-weight: bold;
  color: #323232;
  padding-left: 22px;
  background: url('../../../assets/notice-qd.png') no-repeat left center; 
}
div.el-carousel__item > a > p {
  float: left;
  font-size: 0.75rem;
  background: url('../../../assets/timeic.png') no-repeat left center;
  margin-left: 1rem;
  padding-left: 16px;
}
</style>